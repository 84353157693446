<template>
  <div>
    <div
      v-if="link"
      v-b-modal.link-equipment-modal
    >
      {{ label || 'Привязать' }}
    </div>
    <BButton
      v-else
      v-b-modal.link-equipment-modal
      variant="primary"
    >
      {{ label || 'Привязать' }}
    </BButton>

    <BModal
      id="link-equipment-modal"
      v-model="modal"
      modal-class="link-equipment-modal"
      title="Изменить оборудование"
      body-class="m-0 p-0"
      centered
      size="xl"
      @close="resetModal"
    >
      <EquipmentsTable
        :custom-per-page="5"
        class="link-equipments-table"
        no-export
        no-tabs
        no-creating
        no-cache
        link
        :selected.sync="selected"
      />

      <template #modal-footer>
        <BButton @click="closeModal">
          Отменить
        </BButton>
        <BButton
          variant="primary"
          :disabled="!touched"
          @click="createLink"
        >
          Сохранить
        </BButton>
      </template>
    </BModal>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue';
import {
  ref, computed, toRefs, inject, watch,
} from '@vue/composition-api';
import { useStore } from '@/hooks/useStore';

export default {
  components: {
    BButton,
    EquipmentsTable: () => import('../entityTables/EquipmentsTable.vue'),
  },
  props: {
    facilityId: [String, Number],
    requestId: [String, Number],
    label: String,
    link: Boolean,
    linked: Array,
  },
  setup(props, { emit }) {
    const { requestId, facilityId, linked } = toRefs(props);
    const store = useStore();

    const modal = ref(false);
    const touched = ref(false);

    const selected = ref(linked.value || []);
    const equipments = computed(() => store.getters.allEquipments);
    const resetModal = () => {
      selected.value = linked.value;
    };
    const closeModal = async () => {
      modal.value = false;
      resetModal();
    };

    const needToUpdate = inject('needToUpdate');
    const createLink = async () => {
      if (!selected.value) selected.value = [];
      let response;
      if (requestId.value) {
        response = await store.dispatch('requests/update', {
          id: requestId.value,
          model: {
            equipments_ids: selected.value,
          },
        });
      } else if (facilityId.value) {
        response = await store.dispatch('facilities/updateFacility', {
          facilityId: facilityId.value,
          model: {
            equipment_ids: selected.value,
          },
        });
      } else {
        emit('updateFields', {
          model: {
            equipment_ids: selected.value,
            equipments_ids: selected.value,
          },
        });
      }

      if (response?.result) {
        needToUpdate();
      }
      closeModal();
    };

    watch(selected, () => {
      touched.value = true;
    });

    watch(linked, (payload) => {
      if (typeof payload === 'object') {
        selected.value = payload;
      }
    }, { immediate: true });

    return {
      modal,
      selected,
      equipments,
      resetModal,
      closeModal,
      createLink,
      touched,
    };
  },
};
</script>

<style lang="sass">
.link-equipments-table
  padding: 0 !important
  // max-height: 80vh
  // overflow: auto
  > .card
    margin: 0
#link-equipment-modal
  & .modal-dialog
    .equipments-table
      & td
        max-width: 281px
        overflow: hidden
        & .text-nowrap
          white-space: normal !important
      &-header
        margin-bottom: 1.5rem
      &-bottom
        margin-top: 12px
      &-wrapper
        max-height: calc(100vh - 259px)
        overflow-y: auto
    .table-responsive
      margin-bottom: 0 !important
      margin-top: 0 !important
      border-bottom: 1px solid #eee
</style>
