<template>
  <div class="equipments-table pb-2">
    <b-card
      no-body
      class="mb-0"
    >
      <div class="equipments-table-header mx-2 mt-2">
        <!-- Table Top -->
        <b-row v-if="!noHeader">
          <!-- Per Page -->
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                v-model="searchQuery"
                debounce="500"
                :placeholder="t('dictionary.search')"
              />
            </b-input-group>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="8"
          >
            <div
              v-if="userRole.name === 'admin'"
              class="d-flex align-items-center justify-content-end"
            >
              <b-button
                v-if="!noExport && $store.getters['user/permission']('equipment:export')"
                v-b-modal.excel-modal
                variant="white"
                size="sm"
                class="mr-2 btn btn-outline-primary"
              >
                <feather-icon
                  class="text-primary"
                  size="20"
                  icon="ShareIcon"
                />
              </b-button>
              <linkEquipment
                v-if="byFacilityId && linkingButton &&
                  $store.getters['user/permission']('facilities:update')"
                :facility-id="byFacilityId"
                :linked="linkedToFacility"
                class="mr-2"
              />
              <AddEquipment
                v-if="!noCreating && $store.getters['user/permission']('equipment:create') &&
                  (byFacilityId ? $store.getters['user/permission']('facilities:update') : true)"
                :facility-id="byFacilityId"
                :equipment-parents="allEquipmentParents"
                :checklists="allChecklist"
                @updateData="getAllEquipments"
              />
            </div>
          </b-col>
        </b-row>
        <b-tabs
          v-if="!noTabs"
          v-model="tabIndex"
          class="mt-2"
        >
          <b-tab title="Оборудование" />
          <b-tab title="Запчасть" />
        </b-tabs>
      </div>
      <b-modal
        v-if="userRole.name === 'admin'"
        id="excel-modal"
        ref="excelModal"
        centered
        title="Импорт из Excel"
        size="sm"
        hide-footer
      >
        <div>
          <p>Импорт из Excel поддерживает следующие форматы файлов .xls, .xlsx, .ods и .csv</p>
          <div class="d-flex justify-content-between align-items-center mt-4 mb-2">
            <b-button
              variant="primary"
              @click="excelImport"
            >
              <span class="text-nowrap">
                Загрузить
              </span>
            </b-button>
            <a
              id="close"
              class="text-primary cursor-pointer"
              style="text-decoration: underline"
              :href="exampleExcelUrl"
              @click="downloadExcel"
            >Скачать пример</a>
          </div>
        </div>
      </b-modal>
      <BOverlay
        :show="isProcessing"
        opacity="0.8"
        variant="transparent"
        class="equipments-table-wrapper"
      >
        <b-table-simple
          :class="[{ 'mt-2': noTabs }, 'equipments-table']"
          hover
          small
          caption-top
          responsive
        >
          <b-thead
            class="cols-12"
          >
            <b-tr>
              <b-th
                v-for="column in tableColumns"
                :key="column.key"
                style="padding-top: 0.76rem; padding-bottom: 0.75rem"
                class="p-1 col-1 text-center"
              >
                <div
                  v-if="
                    column.key === 'title' ||
                      column.key === 'model' ||
                      column.key === 'article' ||
                      column.key === 'manufacturer'
                  "
                  :class="
                    `cursor-pointer text-nowrap d-flex
                    align-items-center justify-content-between
                     ${column.key === 'title' ? 'text-left ml-5' : 'text-center'}`
                  "
                  @click="changeSortBy(column.key)"
                >
                  <span>{{ column.label }}</span>
                  <div style="height: 2rem">
                    <div style="height: 0.6rem">
                      <feather-icon
                        :class="sortBy === column.key && sortDesc ? 'text-dark' : 'text-muted'"
                        size="10"
                        icon="ChevronUpIcon"
                      />
                    </div>
                    <div style="height: 0.6rem">
                      <feather-icon
                        :class="sortBy === column.key && !sortDesc ? 'text-dark' : 'text-muted'"
                        icon="ChevronDownIcon"
                        size="10"
                      />
                    </div>
                  </div>
                </div>
                <div
                  v-else
                  class="text-nowrap"
                  style="margin-top: 0.35rem"
                >
                  <span>{{ column.label }}</span>
                </div>
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody v-if="!equipments.length && !isProcessing">
            <b-tr>
              <b-td
                colspan="6"
                class="text-center p-2"
              >
                Ничего не найдено
              </b-td>
            </b-tr>
          </b-tbody>
          <b-tbody
            v-for="equipment in equipments"
            :key="equipment.id"
          >
            <b-tr>
              <b-td
                class="p-1"
                @click="onRowClicked(equipment)"
              >
                <div
                    class="d-flex align-items-center"
                    :style="isMobile ? 'width: 230px' : ''"
                >
                  <div v-if="link">
                    <b-form-checkbox
                      :id="`selected-${equipment.id}`"
                      v-model="selectedEquipments"
                      :value="equipment.id"
                      name="selectedCheckboxes"
                    />
                  </div>
                  <div
                    v-if="!tabIndex"
                    class="d-flex justify-content-between align-items-center mx-2"
                  >
                    <feather-icon
                      :class="equipment.equipments.length ? 'text-dark' : 'text-muted'"
                      :style="equipment.equipments.length ? '' : 'opacity: 0'"
                      size="18"
                      :icon="
                        clickedRow === equipment.id && equipment.equipments.length
                          ? 'ChevronUpIcon'
                          : 'ChevronDownIcon'
                      "
                    />
                  </div>
                  <b-media vertical-align="center">
                    <template #aside>
                      <b-avatar
                        size="32"
                        :src="equipment.avatar"
                        :text="avatarText(equipment.title)"
                        variant="light-primary"
                      />
                    </template>
                  </b-media>
                  <span
                      :class="'font-weight-bolder ' + (isMobile ? 'text-break' : '')"
                  >
                    {{ equipment.title }}
                  </span>
                </div>
              </b-td>
              <b-td
                class="p-1"
                @click="onRowClicked(equipment)"
              >
                <div class="font-weight-bolder text-nowrap">
                  {{ equipment.article }}
                </div>
              </b-td>
              <b-td
                class="p-1"
                @click="onRowClicked(equipment)"
              >
                <div class="font-weight-bolder text-nowrap">
                  {{ equipment.model }}
                </div>
              </b-td>
              <b-td
                class="p-1"
                @click="onRowClicked(equipment)"
              >
                <div class="font-weight-bolder text-nowrap">
                  {{ equipment.manufacturer }}
                </div>
              </b-td>
              <b-td @click="onRowClicked(equipment)">
                <div
                  v-if="equipment.checklists.length"
                  class="font-weight-bolder text-center"
                >
                  <b-badge
                    v-for="(checklist, index) in equipment.checklists"
                    :key="checklist.id"
                    style="margin-bottom: 2px"
                    variant="info"
                  >
                    {{
                      checklist.name.length > 20
                        ? `${index + 1 + '. ' + checklist.name.substring(0, 20)}...`
                        : index + 1 + '. ' + checklist.name
                    }}
                  </b-badge>
                </div>
                <div
                  v-else
                  class="text-center"
                >
                  пусто
                </div>
              </b-td>
              <b-td
                v-if="!link"
                class="p-1 pl-3"
              >
                <b-modal
                  :id="`equipment-delete-modal-${equipment.id}`"
                  centered
                  cancel-variant="outline-secondary"
                  ok-variant="danger"
                  title="Удаление оборудование"
                  size="sm"
                  ok-title="Удалить"
                  cancel-title="Отмена"
                  @ok="equipmentDelete(equipment.id)"
                >
                  <h5>
                    Вы действительно хотите удалить оборудование {{ equipment.title }} ?
                  </h5>
                  <span>Если оборудование используется в заявке, действие не будет выполнено.</span>
                </b-modal>
                <b-dropdown
                  v-if="$store.getters['user/permission']('equipment:update') ||
                    $store.getters['user/permission']('equipment:create') ||
                    $store.getters['user/permission']('equipment:delete')"
                  variant="white"
                  toggle-class="text-decoration-none relative"
                  no-caret
                >
                  <template #button-content>
                    <feather-icon
                      class="text-body mx-auto font-weight-bolder"
                      icon="MoreVerticalIcon"
                    />
                  </template>
                  <b-dropdown-item
                    v-if="!tabIndex && $store.getters['user/permission']('equipment:create')"
                    v-b-toggle="`sidebar-${equipment.id}-add`"
                    class="border-0"
                    @click.prevent
                  >
                    <feather-icon
                      class="font-weight-bolder mr-1"
                      icon="PlusIcon"
                    />Добавить запчасть
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="$store.getters['user/permission']('equipment:update')"
                    v-b-toggle="`sidebar-${equipment.id}`"
                    class="border-0"
                    @click.prevent
                  >
                    <feather-icon
                      class="font-weight-bolder mr-1"
                      icon="Edit2Icon"
                    />Редактировать
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="$store.getters['user/permission']('equipment:delete')"
                    v-b-modal="`equipment-delete-modal-${equipment.id}`"
                    class="border-0 w-100"
                    @click.prevent
                  >
                    <feather-icon
                        class="text-danger font-weight-bolder mr-1"
                        icon="Trash2Icon"
                    />
                    <span class="text-danger">
                      {{ t('settings.delete') }}
                    </span>
                  </b-dropdown-item>
                </b-dropdown>
              </b-td>
            </b-tr>
            <b-tr
              v-for="item in equipment.equipments"
              v-show="clickedRow === equipment.id"
              :key="item.id"
            >
              <b-td class="text-center">
                <div class="pl-5 d-flex align-items-center">
                  <div v-if="link">
                    <b-form-checkbox
                      :id="`selected-${item.id}`"
                      v-model="selectedEquipments"
                      :value="item.id"
                      name="selectedCheckboxes"
                    />
                  </div>
                  <b-media
                    class="ml-1"
                    vertical-align="center"
                  >
                    <template #aside>
                      <b-avatar
                        size="32"
                        :src="item.avatar"
                        :text="avatarText(item.title)"
                        variant="light-primary"
                      />
                    </template>
                  </b-media>
                  <span>{{ item.title }}</span>
                </div>
              </b-td>
              <b-td class="px-1 text-nowrap">
                {{ item.article }}
              </b-td>
              <b-td class="px-1">
                {{ item.model }}
              </b-td>
              <b-td class="px-1 text-nowrap">
                {{ item.manufacturer }}
              </b-td>
              <b-td>
                <div
                  v-if="item.checklists.length"
                  class="font-weight-bolder text-center"
                >
                  <b-badge
                    v-for="(checklist, index) in item.checklists"
                    :key="checklist.id"
                    style="margin-bottom: 2px"
                    variant="info"
                  >
                    {{
                      checklist.name.length > 20
                        ? `${index + 1 + '. ' + checklist.name.substring(0, 20)}...`
                        : index + 1 + '. ' + checklist.name
                    }}
                  </b-badge>
                </div>
                <div
                  v-else
                  class="text-center"
                >
                  пусто
                </div>
              </b-td>
              <b-td
                v-if="!noCreating"
                class="p-1 pl-3"
              >
                <b-modal
                  :id="`equipment-delete-modal-${item.id}`"
                  centered
                  title="Удаление запчастя"
                  size="sm"
                  ok-title="Удалить"
                  cancel-title="Отмена"
                  @ok="equipmentDelete(item.id)"
                >
                  <h5>Вы действительно хотите удалить запчасть {{ item.title }} ?</h5>
                </b-modal>
                <AddEquipment
                  v-if="!noCreating"
                  :edit="true"
                  :sidebar-id="`sidebar-${item.id}`"
                  :equipment="item"
                  :equipment-parents="allEquipmentParents"
                  :checklists="allChecklist"
                  @updateData="getAllEquipments"
                />
                <b-dropdown
                  variant="white"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template #button-content>
                    <feather-icon
                      class="text-body font-weight-bolder"
                      icon="MoreVerticalIcon"
                    />
                  </template>
                  <b-dropdown-item
                    v-b-toggle="`sidebar-${item.id}`"
                    class="border-0"
                    @click.prevent
                  >
                    <feather-icon
                      class="font-weight-bolder mr-1"
                      icon="Edit2Icon"
                    />Редактировать
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-b-modal="`equipment-delete-modal-${item.id}`"
                    class="border-0 w-100"
                  >
                    <feather-icon
                        class="font-weight-bolder mr-1"
                        icon="Trash2Icon"
                    />
                    <span>
                      {{ t('settings.delete') }}
                    </span>
                  </b-dropdown-item>
                </b-dropdown>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </BOverlay>
      <div
        v-if="equipments.length"
        class="equipments-table-bottom mx-2 mb-2"
      >
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center
              justify-content-sm-start
              text-muted
            "
          >
            <span class="mr-1">{{ t('app.table.show-by') }}</span>
            <b-form-group style="width: 90px; margin: 0">
              <AppSelect
                v-model="pagination.perPage"
                :searchable="false"
                label="title"
                :clearable="false"
                :options="perPageOptions"
              />
            </b-form-group>
            <span class="ml-1">
              {{ t('app.table.of', { total: equipmentsTotal }) }}
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-if="total > 1"
              v-model="pagination.page"
              :total-rows="equipmentsTotal"
              :per-page="pagination.perPage"
              first-number
              last-number
              :limit="isMobile ? 1 : 5"
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <template>
      <div
          v-for="equipment in equipments"
          :key="equipment.id + 'modal'"
        >
        <AddEquipment
            v-if="!isProcessing && !noCreating"
            :edit="true"
            :sidebar-id="`sidebar-${equipment.id}`"
            :equipment="equipment"
            :equipment-parents="allEquipmentParents"
            :checklists="allChecklist"
            @updateData="getAllEquipments"
        />
        <AddEquipment
            v-if="!isProcessing && !noCreating"
            :sidebar-id="`sidebar-${equipment.id}-add`"
            :add-spare-part="true"
            :equipment="equipment"
            :equipment-parents="allEquipmentParents"
            :checklists="allChecklist"
            @updateData="getAllEquipments"
        />
      </div>
    </template>
  </div>
</template>

<script>
import {
  BAvatar,
  BButton,
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BDropdown,
  BDropdownItem,
  BThead,
  BTbody,
  BMedia,
  BTr,
  BTh,
  BTd,
  BOverlay,
  BPagination,
  BRow,
  BTableSimple,
  VBToggle,
  BModal,
  VBModal,
  BTabs,
  BTab,
  BBadge,
  BFormCheckbox,
} from 'bootstrap-vue';
import {
  computed, ref, onMounted, watch, toRefs,
} from '@vue/composition-api';
import { avatarText } from '@core/utils/filter';
import Ripple from 'vue-ripple-directive';
import {
  getEquipments,
  deleteEquipment,
  getSpareparts,
  importExcel, downloadExampleExcel,
} from '@/store/equipments/equipments.api';
import AppSelect from '@/components/appSelect/AppSelect.vue';
import { useI18n } from '@/hooks/useI18n';
import { useToast } from '@/hooks/useToast';
import AddEquipment from '@/views/equipment/addEquipment/AddEquipment.vue';
import linkEquipment from '@/components/facilities/LinkEquipment.vue';
import { useStore } from '@/hooks/useStore';
import useCache from '@/hooks/useCache';

export default {
  name: 'EquipmentsTable',
  components: {
    AddEquipment,
    BAvatar,
    BButton,
    BCard,
    BCol,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BThead,
    BTbody,
    BMedia,
    BTr,
    BTh,
    BTd,
    BOverlay,
    BPagination,
    BRow,
    BTableSimple,
    AppSelect,
    BModal,
    BTabs,
    BTab,
    BBadge,
    linkEquipment,
    BFormCheckbox,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    byFacilityId: Number,
    noExport: Boolean,
    noTabs: Boolean,
    noCreating: Boolean,
    noHeader: Boolean,
    linkingButton: Boolean,
    customPerPage: {
      type: Number,
      default: 10,
    },
    noCache: Boolean,
    link: Boolean,
    selected: Array,
    linkedToFacility: Array,
  },
  setup(props, { emit }) {
    const {
      byFacilityId, link, customPerPage, noCache,
    } = toRefs(props);
    const store = useStore();
    const userRole = computed(() => store.getters['user/role']);
    const { t } = useI18n();
    const tabIndex = ref(0);
    const excelModal = ref(null);
    const usersTable = ref(null);
    const isMobile = ref(false);

    isMobile.value = store.state.app.isMobile;

    const tableColumns = [
      {
        key: 'title',
        label: 'Название',
        sortable: true,
      },
      {key: 'article', label: 'Артикул', sortable: false},
      {key: 'model', label: 'Модель', sortable: true},
      {key: 'manufacturer', label: 'Производитель', sortable: true},
      {key: 'checklists', label: 'Чек-лист', sortable: false},
    ];
    if (!link.value) tableColumns.push({ key: 'action', label: 'Действия', sortable: false });
    const { successToast, dangerToast } = useToast();

    if (!store.state.checklists.length) store.dispatch('getChecklists');
    if (!store.state.equipments.length) store.dispatch('getEquipments');
    const allChecklist = computed(() => store.getters.allChecklists);

    const equipments = ref([]);
    const equipmentsTotal = ref(0);

    const allEquipmentParents = computed(() => {
      if (!byFacilityId.value) return store.getters.allEquipments;
      return equipments.value.map((item) => ({
        title: item.title.length > 25 ? `${item.title.substring(0, 26)}...` : item.title,
        value: item.id,
      }));
    });

    const cache = useCache();
    const cacheParams = ref({
      prefix: 'equipmentsTable',
      storage: !noCache.value,
      url: !noCache.value,
    });
    const paginationPage = cache.create({
      defaultValue: '1',
      name: 'page',
      ...cacheParams.value,
    });
    const paginationPerPage = cache.create({
      // eslint-disable-next-line radix
      defaultValue: parseInt(customPerPage.value) || '10',
      name: 'perPage',
      ...cacheParams.value,
    });

    const pagination = ref({
      page: paginationPage,
      perPage: paginationPerPage,
    });
    const total = computed(() => Math.ceil(equipmentsTotal.value / pagination.value.perPage));
    const perPageOptions = ['10', '25', '50', '100'];

    const searchQuery = cache.create({
      defaultValue: '',
      name: 'query',
      ...cacheParams.value,
      // storage: false,
    });
    const sortBy = cache.create({
      defaultValue: 'id',
      name: 'sortBy',
      ...cacheParams.value,
    });
    const sortDesc = cache.create({
      defaultValue: false,
      name: 'sortDesc',
      ...cacheParams.value,
      boolean: true,
    });

    const isProcessing = ref(false);

    const exampleExcelUrl = ref('');

    const downloadExample = async () => {
      const response = await downloadExampleExcel();
      exampleExcelUrl.value = response.data.response.url;
    };

    downloadExample();

    const getAllEquipments = async () => {
      isProcessing.value = true;

      if (byFacilityId.value) {
        const response = await store.dispatch('equipments/search', {
          facility_id: byFacilityId.value,
          sortBy: sortBy.value,
          sortDesc: sortDesc.value,
          q: searchQuery.value,
          ...pagination.value,
        });
        if (response.result) {
          equipments.value = response.data.data;
          // pagination.value.perPage = response.data.per_page;
          equipmentsTotal.value = response.data.total;
        }
        isProcessing.value = false;
      } else {
        (tabIndex.value
          ? getSpareparts({
            sortBy: sortBy.value,
            q: searchQuery.value,
            ...pagination.value,
          })
          : getEquipments({
            sortBy: sortBy.value,
            sortDesc: sortDesc.value,
            q: searchQuery.value,
            ...pagination.value,
          })
        )
          .then((res) => {
            equipments.value = res.data.response.data;
            // pagination.value.perPage = res.data.response.per_page;
            equipmentsTotal.value = res.data.response.total;
          })
          .finally(() => {
            isProcessing.value = false;
          });
      }
    };

    const clickedRow = ref(null);

    onMounted(() => {
      getAllEquipments();
    });

    watch(tabIndex, () => getAllEquipments(), { deep: true });
    const isNeedToUpdateSortNavigation = ref(false);
    let sortNavigationDebounceTimer = null;
    watch([sortBy, sortDesc], () => {
      clearTimeout(sortNavigationDebounceTimer);
      if (sortBy.value) {
        sortNavigationDebounceTimer = setTimeout(() => {
          isNeedToUpdateSortNavigation.value = true;
          sortNavigationDebounceTimer = null;
        }, 5);
      }
    });
    watch(isNeedToUpdateSortNavigation, (value) => {
      if (value) {
        getAllEquipments();
        isNeedToUpdateSortNavigation.value = false;
      }
    });
    const page = computed(() => pagination.value.page);
    const perPage = computed(() => pagination.value.perPage);
    watch(
      [page],
      () => {
        getAllEquipments();
      },
      { deep: true },
    );
    watch(perPage, () => {
      pagination.value.page = 1;
      getAllEquipments();
    });

    watch(searchQuery, () => {
      getAllEquipments();
    });

    const onRowClicked = (equipment) => {
      if (!tabIndex.value) {
        clickedRow.value !== equipment.id
          ? (clickedRow.value = equipment.id)
          : (clickedRow.value = null);
      }
    };

    const changeSortBy = (sort) => {
      sort !== sortBy.value ? (sortBy.value = sort) : (sortDesc.value = !sortDesc.value);
    };

    const downloadExcel = () => {
      excelModal.value.toggle('#close');
    };

    const excelImport = () => {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = ['.xls', '.xlsx', '.ods', '.csv'].join(',');

      input.addEventListener('change', (e) => {
        const file = e.target.files[0];
        const fd = new FormData();
        fd.append('file', file);
        importExcel(fd)
          .then(() => successToast('Загрузка завершена'))
          .finally(() => {
            excelModal.value.toggle('#close');
            getAllEquipments();
          });
      });
      input.addEventListener('error', () => {
        input.remove();
      });

      input.click();
    };

    const equipmentDelete = (equipmentId) => {
      deleteEquipment(equipmentId)
          .then(() => getAllEquipments())
          .catch((res) => {
            if (res.response.status === 403) {
              dangerToast(
                  t('errors.something-went-wrong.text'),
                  res.response.data.message
              );
            }
          });
    };

    const selectedEquipments = ref([]);
    watch(selectedEquipments, (value) => {
      emit('update:selected', value);
    });

    const { selected } = toRefs(props);
    watch(selected, (payload) => {
      try {
        if (JSON.stringify(payload) !== JSON.stringify(selectedEquipments.value)) {
          selectedEquipments.value = payload;
        }
      } catch (e) {
        console.log(e);
      }
    }, { immediate: true });

    const { VUE_APP_API_HOST } = process.env;

    return {
      excelImport,
      clickedRow,
      equipmentDelete,
      downloadExcel,
      excelModal,
      changeSortBy,
      t,
      userRole,
      tabIndex,
      usersTable,
      tableColumns,
      getAllEquipments,
      equipments,
      equipmentsTotal,
      pagination,
      total,
      perPageOptions,
      searchQuery,
      sortBy,
      sortDesc,
      isProcessing,
      onRowClicked,
      avatarText,
      allChecklist,
      allEquipmentParents,
      selectedEquipments,
      VUE_APP_API_HOST,
      isMobile,
      exampleExcelUrl,
    };
  },
};
</script>

<style lang="sass">
.equipments-table
  display: flex
  flex-direction: column
  & th
    padding: 5px !important
  > .card
    flex: 1
</style>
