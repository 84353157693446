<template>
  <div>
    <b-button
      v-if="!edit && !addSparePart"
      v-b-toggle
      href="#sidebar-create"
      variant="primary"
      @click.prevent
    >
      <span class="text-nowrap">
        Добавить
      </span>
    </b-button>
    <b-sidebar
      :id="sidebarId"
      v-model="isSidebarExpanded"
      bg-variant="white"
      no-header
      backdrop
      right
      shadow
      class="equipment-sidebar"
      title="Создать"
    >
      <div class="d-flex flex-column h-100">
        <div class="sidebar-content ">
          <div class="sidebar-header d-flex justify-content-between p-1 bg-">
            <div class="title">
              {{ edit ? 'Редактировать' : addSparePart
                ? 'Добавить запчасть' : 'Добавить оборудование' }}
            </div>
            <div class="action">
              <feather-icon
                class="cursor-pointer"
                icon="XIcon"
                size="18"
                @click="toggleSidebar"
              />
            </div>
          </div>
          <validation-observer ref="equipmentForm">
            <b-form @submit.prevent>
              <b-overlay
                :show="isDeleting"
                variant="transparent"
              >
                <b-media no-body>
                  <div class="d-flex align-items-center justify-content-center">
                    <b-avatar
                      variant="primary"
                      :src="selectedImage.src"
                      size="80px"
                      class="ml-2 mt-2 pull-up"
                      rounded
                      :button="true"
                      @click="selectFile"
                    >
                      <feather-icon
                        v-if="!selectedImage.src"
                        class="shadow"
                        size="40"
                        icon="CameraIcon"
                      />
                    </b-avatar>
                    <div class="ml-4 mt-2">
                      <div>
                        <b-button
                          variant="primary"
                          size="sm"
                          class="mb-75 mr-75 mt-1"
                          @click="selectFile"
                        >
                          Загрузить
                        </b-button>
                      </div>

                      <div class="d-flex justify-content-center">
                        <b-button
                          variant="outline-primary"
                          size="sm"
                          class="mb-75 mr-75"
                          @click="deleteImage"
                        >
                          Удалить
                        </b-button>
                      </div>
                    </div>
                  </div>
                </b-media>
                <b-form-group
                  label="Тип"
                  label-for="type"
                  class="mx-2 mt-2"
                >
                  <b-form-select
                    id="type"
                    v-model="equipmentData.type"
                    :options="equipmentTypes"
                    :disabled="addSparePart"
                  />
                </b-form-group>
                <b-form-group
                  v-if="equipmentData.type === 'spare_part' || addSparePart"
                  label="Родитель"
                  label-for="parent"
                  class="mx-2 mt-2"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Родитель"
                    rules="required"
                  >
                    <v-select
                      id="parent"
                      v-model="equipmentData.parent_id"
                      placeholder="Выберите родителя"
                      label="title"
                      :options="equipmentParents"
                      :reduce="option => option.value"
                    />
                    <!-- <b-form-select
                      id="parent"
                      v-model="equipmentData.parent_id"
                      :options="[
                        {text: 'Выберите родителя', value: null, disabled: true},
                        ...equipmentParents.map((item) => ({
                          text: item.title,
                          value: item.id,
                        }))
                      ]"
                      :state="errors.length > 0 ? false:null"
                      :disabled="addSparePart"
                    /> -->
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label="Чек-лист"
                  label-for="checklist"
                  class="mx-2 mt-2"
                >
                  <v-select
                    id="checklist"
                    v-model="equipmentData.checklist_id"
                    placeholder="Выберите чек-лист"
                    multiple
                    label="title"
                    :options="checklists"
                    :reduce="option => option.value"
                  />
                </b-form-group>
                <b-form-group
                  class="mx-2 mt-2"
                  label="Название"
                  label-for="nameInput"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Название"
                    rules="required"
                  >
                    <b-form-input
                      id="nameInput"
                      v-model="equipmentData.title"
                      placeholder="Введите название"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  class="mx-2 mt-2"
                  label="Артикул"
                  label-for="articleInput"
                >
                  <b-form-input
                    id="articleInput"
                    v-model="equipmentData.article"
                    placeholder="Введите артикул"
                  />
                </b-form-group>
                <b-form-group
                  class="mx-2 mt-2"
                  label="Производитель"
                  label-for="manufacturerInput"
                >
                  <b-form-input
                    id="manufacturerInput"
                    v-model="equipmentData.manufacturer"
                    placeholder="Введите производителя"
                  />
                </b-form-group>
                <b-form-group
                  class="mx-2 mt-2"
                  label="Модель"
                  label-for="modelInput"
                >
                  <b-form-input
                    id="modelInput"
                    v-model="equipmentData.model"
                    placeholder="Введите модель"
                  />
                </b-form-group>
                <b-form-group
                  class="mx-2 mt-2 pb-5"
                  :label="`Описание ${equipmentData.type === 'equipment'
                    ? 'оборудования' : 'запчастья' }`"
                  label-for="description"
                >
                  <b-form-textarea
                    id="description"
                    v-model="equipmentData.description"
                    placeholder="Описание оборудования"
                    rows="3"
                  />
                </b-form-group>
              </b-overlay>
            </b-form>
          </validation-observer>
        </div>
        <footer class="sidebar-footer p-2">
          <div
            class="
              d-flex justify-content-between"
          >
            <b-button
              variant="outline-primary"
              @click="cancel"
            >
              Отменить
            </b-button>
            <b-button
              :disabled="disableButton"
              variant="primary"
              @click="submitData"
            >
              Сохранить
            </b-button>
          </div>
        </footer>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import vSelect from 'vue-select';
import {
  BButton,
  BForm,
  VBToggle,
  BSidebar,
  BAvatar,
  BMedia,
  BOverlay,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormSelect,
} from 'bootstrap-vue';
import {
  computed,
  ref,
  watch,
  toRefs,
} from '@vue/composition-api';
import Ripple from 'vue-ripple-directive';
import {
  createEquipment,
  updateEquipment,
  addEquipmentAvatar,
  deleteEquipmentAvatar,
} from '@/store/equipments/equipments.api';
import { useToast } from '@/hooks/useToast';
import { useStore } from '@/hooks/useStore';
import { useI18n } from '@/hooks/useI18n';

const avatarSize = 1024 * 800;
export default {
  name: 'AddEquipment',
  components: {
    BFormTextarea,
    BFormGroup,
    BFormInput,
    BButton,
    BSidebar,
    BAvatar,
    BMedia,
    BForm,
    BOverlay,
    BFormSelect,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  props: {
    equipmentParents: {
      type: Array,
      default: () => [],
    },
    checklists: {
      type: Array,
      default: () => [],
    },
    equipment: {
      type: Object,
      default: () => {},
    },
    edit: {
      type: Boolean,
      default: false,
    },
    addSparePart: {
      type: Boolean,
      default: false,
    },
    sidebarId: {
      type: String,
      default: 'sidebar-create',
    },
    facilityId: Number,
  },
  setup(props, { emit }) {
    const store = useStore();
    const user = computed(() => store.getters['user/account']);
    const { facilityId } = toRefs(props);

    const equipmentForm = ref(null);
    const validate = async () => {
      const success = await equipmentForm.value.validate();
      return success
        ? Promise.resolve()
        : Promise.reject();
    };
    const resetValidationObserver = () => {
      equipmentForm.value.reset();
    };

    const equipmentData = ref({
      type: 'equipment',
      title: '',
      article: '',
      parent_id: null,
      checklist_id: [],
      manufacturer: '',
      model: '',
      description: '',
      account_id: user.value?.creator?.id,
      account_user_id: user.value?.creator?.id,
    });

    const selectedImage = ref({
      file: null,
      src: null,
    });

    const setEquipmentData = () => {
      if (props.edit) {
        equipmentData.value.type = props.equipment.type;
        equipmentData.value.title = props.equipment.title;
        equipmentData.value.article = props.equipment.article;
        equipmentData.value.manufacturer = props.equipment.manufacturer;
        equipmentData.value.model = props.equipment.model;
        equipmentData.value.description = props.equipment.description;
        equipmentData.value.parent_id = props.equipment.parent_id;
        equipmentData.value.account_id = props.equipment.account_id;
        equipmentData.value.account_user_id = props.equipment.account_user_id;
        equipmentData.value.checklist_id = props.equipment.checklists?.map((item) => item.id);
        selectedImage.value.src = props.equipment.avatar;
      } else if (props.addSparePart) {
        equipmentData.value.parent_id = props.equipment.id;
        equipmentData.value.type = 'spare_part';
      }
    };

    setEquipmentData();

    const { dangerToast, successToast } = useToast();
    const equipmentTypes = ref([
      {
        value: 'equipment',
        text: 'Оборудование',
      },
      {
        value: 'spare_part',
        text: 'Запчасть',
      },
    ]);

    const isSidebarExpanded = ref(false);
    const toggleSidebar = () => {
      isSidebarExpanded.value = !isSidebarExpanded.value;
    };

    watch(isSidebarExpanded, () => {
      resetValidationObserver();
    });
    const { t } = useI18n();

    const deleteImage = () => {
      selectedImage.value = {
        file: null,
        src: null,
      };
    };

    const isDeleting = ref(false);
    const selectFile = () => {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = ['image/png', 'image/jpeg', 'image/gif'].join(',');

      input.addEventListener('change', (e) => {
        const file = e.target.files[0];
        if (!file) return;
        if (file.size <= avatarSize) {
          selectedImage.value = {
            file,
            src: URL.createObjectURL(file),
          };
          return;
        }
        dangerToast(
          t('settings.avatar.oversizeMessage.title'),
          t('settings.avatar.oversizeMessage.text'),
        );
      });

      input.addEventListener('error', () => {
        input.remove();
      });

      input.click();
    };

    const clearForm = () => {
      equipmentData.value = {
        type: 'equipment',
        title: '',
        article: '',
        checklist_id: [],
        manufacturer: '',
        model: '',
        description: '',
        parent_id: null,
        account_id: 1,
        account_user_id: 1,
      };
      if (props.addSparePart) {
        equipmentData.value.parent_id = props.equipment.id;
      }

      selectedImage.value = {
        file: null,
        src: null,
      };
    };

    const disableButton = ref(false);
    const equipmentUpdate = () => {
      if (selectedImage.value.file && selectedImage.value.src) {
        const fd = new FormData();
        fd.append('avatar', selectedImage.value.file);
        addEquipmentAvatar(props.equipment.id, fd);
      } else if (!selectedImage.value.src) {
        deleteEquipmentAvatar(props.equipment.id);
      }
      if (!equipmentData.value.parent_id) {
        delete equipmentData.value.parent_id;
      }
      disableButton.value = true;
      const equipmentDataCopy = { ...equipmentData.value };
      const formData = new FormData();
      // eslint-disable-next-line no-restricted-syntax
      for (const equipmentEntries of Object.entries(equipmentDataCopy)) {
        if (equipmentEntries[0] === 'checklist_id') {
          // eslint-disable-next-line no-restricted-syntax
          for (const item of equipmentDataCopy.checklist_id) {
            formData.append('checklist_id[]', item);
          }
        } else if (equipmentEntries[1] !== null) {
          formData.append(equipmentEntries[0], equipmentEntries[1]);
        }
      }
      updateEquipment(formData, props.equipment.id)
        .then(() => successToast(('Успешно обновлено')))
        .catch((error) => dangerToast('Ошибка', '', error.message))
        .finally(() => {
          disableButton.value = false;
          toggleSidebar();
          emit('updateData');
        });
    };

    const equipmentCreate = () => {
      if (!equipmentData.value.parent_id) {
        delete equipmentData.value.parent_id;
      }
      disableButton.value = true;
      const equipmentDataCopy = { ...equipmentData.value };
      const formData = new FormData();
      if (facilityId.value && !equipmentData.value.parent_id) {
        formData.append('facility_id', facilityId.value);
      }
      // eslint-disable-next-line no-restricted-syntax
      for (const equipmentEntries of Object.entries(equipmentDataCopy)) {
        if (equipmentEntries[0] === 'checklist_id') {
          // eslint-disable-next-line no-restricted-syntax
          for (const item of equipmentDataCopy.checklist_id) {
            formData.append('checklist_id[]', item);
          }
        } else {
          formData.append(equipmentEntries[0], equipmentEntries[1]);
        }
      }
      createEquipment(formData)
        .then((res) => {
          if (selectedImage.value.file && selectedImage.value.src.length) {
            const fd = new FormData();
            fd.append('avatar', selectedImage.value.file);
            addEquipmentAvatar(res.data.response.id, fd).then(() => { emit('updateData'); });
          }
          successToast('Успешно добавлено');
          store.dispatch('getEquipments');
        })
        .catch((error) => dangerToast('Ошибка', '', error.message))
        .finally(() => {
          emit('updateData');
          disableButton.value = false;
          toggleSidebar();
          clearForm();
        });
    };

    const submitData = () => {
      equipmentForm.value.validate().then((success) => {
        if (success) {
          if (props.edit) {
            equipmentUpdate();
            return;
          }
          equipmentCreate();
        }
      });
    };

    const cancel = () => {
      if (!props.edit) {
        clearForm();
        toggleSidebar();
        return;
      }
      setEquipmentData();
      toggleSidebar();
    };

    watch(isSidebarExpanded, () => {
      if (props.edit && !isSidebarExpanded.value) {
        setEquipmentData();
      } else if (!isSidebarExpanded.value) {
        clearForm();
      }
    });

    return {
      resetValidationObserver,
      validate,
      cancel,
      submitData,
      equipmentForm,
      required,
      disableButton,
      toggleSidebar,
      isSidebarExpanded,
      equipmentData,
      selectFile,
      isDeleting,
      selectedImage,
      equipmentTypes,
      deleteImage,
    };
  },
};
</script>
<style lang="scss">
@import "~@/assets/scss/utils";
.equipment-sidebar{
  .sidebar-header {
    background-color: $body-bg;
  }
  .sidebar-footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    background-color: $white;
    z-index: 3;
  }
}

@include dark-theme {
  .equipment-sidebar {
    .sidebar-header {
      background-color: $theme-dark-body-bg;
    }
    .sidebar-footer {
      background-color: $theme-dark-table-bg;
    }
  }
}

.sidebar-content {
  flex-grow: 1;
  position: relative;
  padding-bottom: 70px;
}
</style>
